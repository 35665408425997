import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import LoginForm from './LoginForm';
import { LogoRound } from '../../../assets/img';
import { ReferralCode } from '../SignUp';
import { notify, getConsumerUrl } from '../../../utils/utilities';
import { default as Landing } from '../../../assets/img/landing/2.jpg';

const buttonClasses = `
  text-lg uppercase rounded-md text-white hover:text-black font-sf-bold
  bg-primary-color border-2
  w-1/2 py-3 md:my-0
  focus:outline-none
`;

let connectProps = {
  ...UserActions,
};

let enhancer = connect(null, connectProps);

function Signin(props) {
  const search = useLocation().search;
  const revealed = new URLSearchParams(search).get("revealed");

  const [showSignIn, setShowSignIn] = useState(!!revealed);
  const revealSignIn = () => {
    setShowSignIn(true);
  }

  const [loader, setLoader] = useState(false);
  const [openReferralPopup, setReferralPopup] = useState(false);

  useEffect(() => {
    props.resetInvitedById();
  }, []);

  const submitSignin = async data => {
    const email = data.email;
    const password = data.password;
    setLoader(true);
    props.signIn({ email, password })
      .finally(() => {
        setLoader(false);
      })
      .then(() => {
        props.getSports();
      }).catch(() => {})
  };

  const validateSignup = () => {
    setReferralPopup(true);
  };

  const checkReferralCodeValid = async code => {
    setReferralPopup(false);
    props.checkReferralCodeValid(code).then(response => {
      response.inviter ? history.push('/athlete/signup') : notify('Invalid invitation code.');
    });
  };

  let history = useHistory();
  return (
    <div className="landing-background" style={{ backgroundImage: "url(" + Landing + ")" }}>
      <div className={`landing-transition backdrop min-h-screen ${showSignIn ? 'pt-0' : 'pt-10'}`}>
        {/*<img className="object-cover h-12 mx-4" src={LogoRound} alt=""/>*/}

        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto opacity-90">
          <div
            className="w-full md:w-1/3 overflow-hidden rounded-lg shadow-md mx-4 md:mx-0"
            style={{minHeight: '480px'}}>

            <div className="w-full flex justify-content-center mb-10 ">
              <img src={LogoRound}/>
            </div>

            <div className="flex w-full justify-between mb-5">
              <button className={`${buttonClasses} border-primary-color mr-1`}
                onClick={() => {
                  window.location.replace(getConsumerUrl() + '?revealed=true');
                }}>
                For Players
              </button>

              <button className={`${buttonClasses}  ${showSignIn ? '' : 'border-primary-color'} `}
                      onClick={ revealSignIn }>
                For Trainers
              </button>
            </div>

            <div className={`landing-transition collapsible  ${showSignIn ? 'expanded' : 'collapsed'}`}>
              <LoginForm
                loader={loader}
                loginParent={data => submitSignin(data)}
                resetPassword={() => history.push('/login/reset')}
                signupParent={validateSignup}
              />

              <ReferralCode
                show={openReferralPopup}
                hideReferralCode={() => setReferralPopup(false)}
                onConfirm={checkReferralCodeValid}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default enhancer(Signin);
