import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import immutableTransform from 'redux-persist-transform-immutable';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { API_URL } from '../constant';
import axios from 'axios';
import { setUploadProgress } from '../../src/store/User/actions';
import errorHandler from '../utils/middlewares/errorHandler';

const middlewares = [
  errorHandler,
  thunk
];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

const persistor = persistStore(store);

const makeHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'AUTH-TOKEN': localStorage.getItem('auth_token'),
  };
};

let _client = null;

const client = () => {
  if (!_client) {
    let auth_token = localStorage.getItem('auth_token');
    _client = axios.create({
      baseURL: API_URL,
      headers: auth_token ? makeHeaders() : axios.defaults.headers.common,
      // timeout: 100000,
      timeoutErrorMessage: 'Oops, Internet seems down! Please check your connection!',
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        store.dispatch(setUploadProgress(percentCompleted));
      },
    });
  }

  return _client;
};

export let setToken = async token => {
  client().defaults.headers.common = {
    'Content-Type': 'application/json',
    'AUTH-TOKEN': token,
  };
};

export let unsetToken = async () => {
  client().defaults.headers.common['AUTH-TOKEN'] = null;
  localStorage.clear();
};

export { store, persistor, client };
